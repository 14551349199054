/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header Styles */
.App-header {
  background-color: #ffffff;
  color: #333333;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
}

.App-header h1 {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
}

/* Section Styles */
.App-section {
  padding: 40px 20px;
  background-color: #ffffff;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.container h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
}

.container p {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555555;
}

.article {
  margin-bottom: 30px;
}

/* Footer Styles */
.App-footer {
  background-color: #ffffff;
  color: #333333;
  padding: 10px 20px;  /* Reduced padding */
  height: 80px;  /* Reduced height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  /* Align content in the center */
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-container {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.footer-info {
  font-size: 12px;
  margin-bottom: 10px;
}

.footer-info p {
  margin: 5px 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}

nav ul li a {
  font-size: 14px;  /* Smaller font size */
  font-weight: 500;
  color: #333333;
  text-decoration: none;  /* Remove underline */
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #0073e6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App-header h1 {
    font-size: 20px;
  }

  .container h2 {
    font-size: 24px;
  }

  .footer-container {
    flex-direction: column;
    gap: 10px;
  }

  .footer-info {
    text-align: center;
  }
}
